/* eslint-disable */
import Vue from "vue"
import axios from 'axios'


const Axios = axios.create({
  baseURL: '/zj-api', // 请求根路径
  timeout: 100000,
  responseType: 'json',
  withCredentials: true, // 设置跨域请求为允许带cookie
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    token: 'token'
  }
})

if (!Vue.prototype.$http) {
  Vue.prototype.$http = Axios
}

export default async ({ app, router, store }) => {
  Vue.prototype.$http.interceptors.request.use(config => { // 请求拦截器
    return config
  }, error => {
    return Promise.reject(error.data.error.message)
  })

  Vue.prototype.$http.interceptors.response.use(res => { // 响应拦截器
    return res.data
  }, error => {
    return Promise.reject(error)
  })
}