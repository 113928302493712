<template>
<div class="flex">
  <div class="b-title">
    <div class="b-title-text" v-html="text"></div>
    <img src="@/assets/title_b_bg.png">
  </div>
</div>  
</template>
<script>
export default {
  props: {
    text: {
      default: '',
      type: String
    }
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: -ms-flex;
  justify-content: center;
}
.b-title {
  margin: 40px 0;
  background: #1A5ECE;
  height: 50px;
  width: auto;
  color: #125FAD;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  .b-title-text {
    padding: 0 10px;
    height: 36px;
    width: auto;
    border-radius: 2px;
    background: #FFF;
    margin-right: 15px;
    line-height: 36px;
    text-align: center;
    font-size: 20px;
    font-weight: 500px;
    white-space: nowrap;
  }
  img {
    float: right;
    width: 30px;
    height: 30px;
  }
}
</style>
