import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import OSS from 'ali-oss'
Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    ossClient: null
  },
  mutations: {
  },
  actions: {
    OSSSign(x) {
      return new Promise((resolve, reject) => {
        if (x.state.ossClient) {
          resolve()
          return
        }
        axios.get('/miaoyi-api/sys/sms/sendOss', {
          headers: {
            Token: localStorage.getItem('token')
          }
        }).then(data => {
          let res = data.data
          if (res.code === 0) {
            x.state.ossClient = new OSS({
              endpoint: 'oss-cn-hangzhou.aliyuncs.com',
              accessKeyId: res.data.accessKey,
              accessKeySecret: res.data.accessSecret,
              stsToken: res.data.token,
              bucket: 'itie-pc'
            })
            resolve()
          }
        })
      })
    },
    uploadFile(x, obj) {
      return new Promise((resolve, reject) => {
        x.dispatch('OSSSign').then(()=>{
          x.state.ossClient.multipartUpload(obj.path+(new Date()).valueOf()+Math.floor(Math.random()*10)+'-'+obj.file.name, obj.file, {
            mime: obj.file.type
          }).then(res=> {
            resolve(res.res.requestUrls[0].replace(/\?uploadId=.*/, '')) // 处理掉后缀所携带的uploadID
          }).catch(err => {
            alert('OSS出错，已重置令牌，请重新上传。')
            x.state.ossClient = null
            reject()
          })
        })
      })
    }
  }
})