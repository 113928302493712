<template>
  <div class="d-title" :style="{'width': width+'px'||'auto'}">
    <div class="d-title-line"></div>
    {{text}}<span v-if="tip" class="tip">{{tip}}</span>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      default: '',
      type: String
    },
    width: {
      default: '',
      type: String
    },
    tip: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.d-title {
  height: 40px;
  max-width: 1080px;
  margin: 0 auto;
  border-bottom: 2px solid #0769CC;
  color: #0769CC;
  line-height: 30px;
  font-size: 20px;
  .d-title-line {
    margin-right: 10px;
    float: left;
    width: 6px;
    height: 30px;
    background: #0769CC;
  }
  .tip {
    margin-left: 10px;
    font-size: 13px;
    color: #666;
  }
}
</style>
