<template>
  <div class="c-title">
    <div class="tl"></div><h2>{{text}}</h2><div class="tr"></div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.c-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  font-size: 21px;
  font-weight: 600;
  line-height: 80px;
  text-align: center;
  h2 {
    font-size: 25px;
    color: #125FAD;
  }
  .tl, .tr {
    width: 150px;
    border-bottom: 1px solid #729EF1;
    height: 1px;
    margin: 20px;
  }
}
</style>
