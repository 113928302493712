import Vue from 'vue'
import App from './App.vue'
import axios from './axios'
import router from './router'
import store from './store'
import '@babel/polyfill'
// import i18n from './i18n'
import iView from 'iview'
import helper from './helper'
import 'iview/dist/styles/iview.css'
import './assets/common.css'
import ATitle from '@/components/ATitle'
import BTitle from '@/components/BTitle'
import CTitle from '@/components/CTitle'
import DTitle from '@/components/DTitle'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.component('ATitle', ATitle)
Vue.component('BTitle', BTitle)
Vue.component('CTitle', CTitle)
Vue.component('DTitle', DTitle)
Vue.config.productionTip = false
Vue.use(iView)
Vue.use(ElementUI)
Vue.use(axios)
Vue.use(helper)

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
