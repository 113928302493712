<template>
  <div class="a-title">{{text}}</div>
</template>
<script>
export default {
  props: {
    text: {
      default: '',
      type: String
    },
  }
}
</script>

<style lang="less" scoped>
.a-title {
  color: #125FAD;
  margin: 30px auto;
  height: 59px;
  width: 350px;
  font-size: 25px;
  letter-spacing: 2px;
  font-weight: 500;
  background-image: url('../assets/title_a_bg.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  text-align: center;
  line-height: 56px;
}
</style>
