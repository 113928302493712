import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        cur: 'sy'
      },
      component: Home
    },{
      path: '/en',
      name: 'enHome',
      meta: {
        cur: 'sy'
      },
      component: () => import('./views/Home_EN.vue')
    },{
      path: '/about',
      name: 'about',
      meta: {
        cur: 'zhfw'
      },
      component: () => import('./views/About.vue')
    },{
      path: '/exhibition',
      name: 'exhibition',
      meta: {
        cur: 'zggk'
      },
      component: () => import('./views/Exhibition.vue')
    },{
      path: '/activity',
      name: 'activity',
      meta: {
        cur: 'tqhd'
      },
      component: () => import('./views/Activity.vue')
    },{
      path: '/activity/:id',
      name: 'activityInfo',
      meta: {
        cur: 'tqhd'
      },
      component: () => import('./views/ActivityInfo.vue')
    },{
      path: '/main-forum',
      name: 'main-forum',
      meta: {
        cur: 'hylt'
      },
      component: () => import('./views/ForumMain.vue')
    },{
      path: '/sub-forum',
      name: 'sub-forum',
      component: () => import('./views/ForumSub.vue')
    },{
      path: '/subject-forum',
      name: 'subject-forum',
      component: () => import('./views/ForumSubject.vue')
    },{
      path: '/forum4',
      name: 'forum4',
      component: () => import('./views/Forum4.vue')
    },{
      path: '/exhibitor-sign',
      name: 'exhibitorSign',
      meta: {
        cur: 'zhfw'
      },
      component: () => import('./views/SignExhibitor.vue')
    },{
      path: '/media-sign',
      name: 'mediaSign',
      meta: {
        cur: 'zhfw'
      },
      component: () => import('./views/SignMedia.vue')
    },{
      path: '/sign/:type',
      name: 'sign',
      meta: {
        cur: 'zhfw'
      },
      component: () => import('./views/Sign.vue')
    },{
      path: '/sponsor',
      name: 'sponsor',
      meta: {
        cur: 'xwzx'
      },
      component: () => import('./views/Sponsor.vue')
    },{
      path: '/catalog',
      name: 'catalog',
      meta: {
        cur: 'wjhg'
      },
      component: () => import('./views/Catalog.vue')
    },{
      path: '/news',
      name: 'news',
      meta: {
        cur: 'xwzx'
      },
      component: () => import('./views/News.vue')
    },{
      path: '/news/:id',
      name: 'news-detail',
      meta: {
        cur: 'xwzx'
      },
      component: () => import('./views/NewsDetail.vue')
    },{
      path: '/post',
      name: 'post',
      meta: {
        cur: 'xwzx'
      },
      component: () => import('./views/Post.vue')
    },{
      path: '/images',
      name: 'images',
      meta: {
        cur: 'xwzx'
      },
      component: () => import('./views/Images.vue')
    },{
      path: '/first',
      name: 'first',
      meta: {
        cur: 'wjhg'
      },
      component: () => import('./views/First.vue')
    },{
      path: '/first-forum',
      name: 'firstForum',
      meta: {
        cur: 'wjhg'
      },
      component: () => import('./views/FirstForumDetail.vue')
    },{
      path: '/second',
      name: 'second',
      meta: {
        cur: 'wjhg'
      },
      component: () => import('./views/Second.vue')
    },{
      path: '/third',
      name: 'third',
      meta: {
        cur: 'wjhg'
      },
      component: () => import('./views/Third.vue')
    },{
      path: '/fourth',
      name: 'fourth',
      meta: {
        cur: 'wjhg'
      },
      component: () => import('./views/Fourth.vue')
    },{
      path: '/fifth',
      name: 'fifth',
      meta: {
        cur: 'wjhg'
      },
      component: () => import('./views/Fifth.vue')
    },{
      path: '/forum5',
      name: 'Forum5',
      meta: {
        cur: 'wjhg'
      },
      component: () => import('./views/Forum5.vue')
    },{
      path: '/hotel',
      name: 'hotel',
      meta: {
        cur: 'zhfw'
      },
      component: () => import('./views/Hotel.vue')
    },{
      path: '/route',
      name: 'route',
      meta: {
        cur: 'zhfw'
      },
      component: () => import('./views/Route.vue')
    },{
      path: '/contact-us',
      name: 'contact-us',
      meta: {
        cur: 'zhfw'
      },
      component: () => import('./views/Contact.vue')
    },{
      path: '/files',
      name: 'files',
      meta: {
        cur: 'zhfw'
      },
      component: () => import('./views/Files.vue')
    }
  ]
})
