const helper = {
  /**
   * 格式化日期 pattern Y-M-D h:m:s
   */
  formatTime(value, pattern) {
    function fill(v) {
      return (v < 10 ? '0' : '') + v;
    }
    pattern = pattern || 'Y-M-D h:m:s';
    const date = new Date(value);
    const len = pattern.length;
    let ret = pattern;
    for (let i = 0; i < len; i++) {
      switch (pattern.charAt(i)) {
        case 'Y':
            ret = ret.replace(/Y/g, fill(date.getFullYear()));
            break;
        case 'y':
            ret = ret.replace(/y/g, fill(date.getFullYear()).substring(2));
            break;
        case 'M':
            ret = ret.replace(/M/g, fill(date.getMonth() + 1));
            break;
        case 'D':
            ret = ret.replace(/D/g, fill(date.getDate()));
            break;
        case 'h':
            ret = ret.replace(/h/g, fill(date.getHours()));
            break;
        case 'm':
            ret = ret.replace(/m/g, fill(date.getMinutes()));
            break;
        case 's':
            ret = ret.replace(/s/g, fill(date.getSeconds()));
            break;
      }
    }
    return ret;
  },

  /**
   * 隐藏手机号中间四位
   */
  maskPhone(n){
    return n.toString().replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
  },
}

export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, '$helper', {value: helper})
  }
}